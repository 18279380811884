.team {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-block: 0.5rem;
  max-width: 600px;
  padding: 1rem;
}

.score {
  padding-right: 3rem;
  text-align: right;
  width: 5rem;
  font-size: 3rem;
}

.photo {
  border-radius: 1rem;
}
