body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Secular One', sans-serif;
  background-color: rgb(19, 19, 23);
  color: white;
}

.load-more {
  display: block;
  margin: auto;
  font-size: 2rem;
  background-color: transparent;
  color: white;
  border: 4px solid darkred;
  width: 15rem;
  border-radius: 1rem;
  margin-block: 3rem;
  padding: 1rem;
}

.load-more:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: rgb(45,45,45)

}