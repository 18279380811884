header {
  /* hier een actergrondje met de top2000 vlakken? */
  background-image: url("../../../server/assets/logo/banner-top-2000-letsescape.jpg");
  background-position: center;
  background-color: rgba(203, 29, 29, 0.5);
  background-blend-mode: color-burn;

  margin-top: 0rem;
  padding-block: 3rem;
  margin-bottom: 3rem;
  text-shadow: 0px 0px 25px black;
}

.header--title {
  text-align: center;
  font-size: 5rem;
  margin-top: 0;
}

.header--searchbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0px 0px 9px black;
}

.header--searchbar #search {
  margin-left: 10px;
  font-size: 3rem;
  border-radius: 1rem;
  text-align: center;
  padding: 0.5rem;
  margin-top: 1rem;
  width: 8rem;
}

.header--searchbar label {
  font-size: 2rem;
  text-align: center;
}
